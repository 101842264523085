import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex, grid, padding } from '../../../../design/animations/display';
import { Button } from '../../../../design/components/button/Button';
import {
  breakpointLarge,
  breakpointSmall,
  breakpointXLarge,
  breakpointXSmall,
} from '../../../../design/responsive/breakpoints';
import { updatePageParam } from '../../ContentHubCards.helpers';
import { HubContent } from '../../types';
import { PaginationControls } from './PaginationControls';
import { Posts } from './Posts';

export const PaginationButton = styled(Button)`
  max-width: fit-content;
`;

export const PageCountContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  ${padding({ y: '40px' })}
  ${flex({ direction: 'column', gap: '16px', align: 'center' })}
  ${breakpointXSmall(css`
    ${flex({ justify: 'space-between', align: 'center', direction: 'row' })}
  `)}
`;

const CardGrid = styled.div`
  max-width: 1280px;
  ${grid({ columns: 1, gap: '24px' })}
  ${breakpointSmall(
    css`
      ${grid({ columns: 2 })}
    `
  )};
  ${breakpointLarge(
    css`
      ${grid({ columns: 3 })}
    `
  )};
  ${breakpointXLarge(
    css`
      ${grid({ columns: 2 })}
    `
  )};
`;

type PaginationProps = {
  itemsPerPage: number;
  items: HubContent[];
};
/**
 * Adds page pagination.
 */
export const PaginatedItems = ({ itemsPerPage, items }: PaginationProps) => {
  const [startOffset, setStartOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [postsToUse, setPostsToUse] = useState<HubContent[]>(items);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setPostsToUse(items);

    if (isFirstLoad) {
      const page = Number(new URLSearchParams(window.location.search).get('page')) || 1;
      const maxPages = Math.ceil(items.length / itemsPerPage);
      const validPage = page > maxPages ? 1 : page;

      if (validPage !== page) {
        updatePageParam(validPage);
      }

      setCurrentPage(validPage);
      setStartOffset((validPage - 1) * itemsPerPage);
      setIsFirstLoad(false);
    } else {
      // Reset to page 1 when items change after initial load
      setCurrentPage(1);
      setStartOffset(0);
      updatePageParam(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handlePageClick = (event: { selected: number }) => {
    const pageNumber = event.selected;
    const indexToStartFrom = pageNumber * itemsPerPage - itemsPerPage;
    setStartOffset(indexToStartFrom);
    setCurrentPage(event.selected);
    return false;
  };

  if (postsToUse.length === 0) return null;
  const endOffset = startOffset + itemsPerPage;
  const pageCount = Math.ceil(postsToUse.length / itemsPerPage);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;
  const postsThisPage = postsToUse.slice(startOffset, endOffset);

  const paginationControlProps = {
    handlePageClick,
    isLastPage,
    currentPage,
    pageCount,
    isFirstPage,
  };

  return (
    <>
      <PaginationControls {...paginationControlProps} testIdPrefix="top-pagination" />
      <CardGrid data-testid="card-grid">
        <Posts currentItems={postsThisPage} />
      </CardGrid>
      <PaginationControls {...paginationControlProps} testIdPrefix="bottom-pagination" />
    </>
  );
};
