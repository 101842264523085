import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { flex, padding } from '../../../../design/animations/display';
import { brand2 } from '../../../../design/colors/brand';
import { Button } from '../../../../design/components/button/Button';
import { breakpointXSmall } from '../../../../design/responsive/breakpoints';
import { BodyP } from '../../../../design/typography/Typography';
import { updatePageParam } from '../../ContentHubCards.helpers';

export const PaginationButton = styled(Button)`
  max-width: fit-content;
`;

export const PageCountContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  ${padding({ y: '40px' })}
  ${flex({ direction: 'column', gap: '16px', align: 'center' })}
  ${breakpointXSmall(css`
    ${flex({ justify: 'space-between', align: 'center', direction: 'row' })}
  `)}
`;

const PageNumbersContainer = styled.div`
  ${flex({ gap: '8px' })}
`;

type PaginationControlsProps = {
  currentPage: number;
  isLastPage: boolean;
  handlePageClick: (event: { selected: number }) => boolean;
  pageCount: number;
  isFirstPage: boolean;
  testIdPrefix: string;
};
/**
 * Pagination Controls FC.
 */
export const PaginationControls: FC<PaginationControlsProps> = ({
  currentPage,
  isLastPage,
  pageCount,
  isFirstPage,
  handlePageClick,
  testIdPrefix,
}) => {
  const onlyHasOnePage = pageCount === 1;
  const updatePage = (newPage: number) => {
    updatePageParam(newPage);
    handlePageClick({ selected: newPage });
  };

  const goPrevPage = () => updatePage(currentPage - 1);
  const goNextPage = () => updatePage(currentPage + 1);

  return (
    <PageCountContainer>
      <PaginationButton
        styleType="reverseTertiary"
        disabled={isFirstPage}
        onClick={goPrevPage}
        data-testid={`${testIdPrefix}-prev-page-button`}
      >
        Previous Page
      </PaginationButton>
      <PageNumbersContainer>
        <BodyP type="bodyMRegular" color={brand2}>
          {currentPage}
        </BodyP>
        <BodyP type="bodyMRegular" color={brand2}>
          of
        </BodyP>
        <BodyP type="bodyMRegular" color={brand2}>
          {onlyHasOnePage ? 1 : pageCount}
        </BodyP>
      </PageNumbersContainer>
      <PaginationButton
        styleType="reverseTertiary"
        disabled={isLastPage || onlyHasOnePage}
        onClick={goNextPage}
        data-testid={`${testIdPrefix}-next-page-button`}
      >
        Next Page
      </PaginationButton>
    </PageCountContainer>
  );
};
