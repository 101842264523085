/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex, margin } from '../../design/animations/display';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { Heading2 } from '../../design/typography/Typography';
import {
  DownState,
  findActiveType,
  formatTypeFilterText,
  getPostsMatchingTopic,
  getPostsMatchingType,
  getQueryStringValue,
  getTopics,
  getTypes,
  isPast,
  queryStringTopics,
  setQueryStringWithoutPageReload,
  sortByDate,
} from './ContentHubCards.helpers';
import { PaginatedItems } from './components/Pagination/Pagination';
import TopicFilter from './components/TopicFilter/TopicFilter';
import TypeFilter from './components/TypeFilter/TypeFilter';
import { BlogPostGridProps } from './types';

const PostsGrid = styled(SliceContainer)`
  ${margin({ bottom: '24px' })}
`;

const FiltersContainer = styled.div`
  ${flex({ direction: 'column', gap: '8px' })}
  ${margin({ top: '40px', bottom: 'none' })}
  position: relative;
  width: 100%;
  max-width: 1280px;
  z-index: 2;
  height: auto;
  ${breakpointSmall(css`
    ${flex({ justify: 'space-between', direction: 'row' })};
    height: 72px;
    width: 100%;
  `)}
`;

const TopicHeading = styled(Heading2)`
  width: 100%;
  ${margin({ top: '40px' })}
`;
/**
 * PostsGrid component renders paginated list of all blog posts.
 */
const ContentHubCards: FC<BlogPostGridProps> = ({ posts, events, guides, stories, preFilters }) => {
  const currentBlogPosts = posts.filter(isPast);
  const allPosts = sortByDate([...currentBlogPosts, ...events, ...guides, ...stories]);

  const config = {
    paginationAmount: 6,
    allTopics: ['All topics', ...getTopics(allPosts)],
    allTypes: ['All posts', ...getTypes(allPosts)],
    allCurrentPosts: allPosts,
  };

  const [activeTopic, setActiveTopic] = useState<string>('');
  const [activeType, setActiveType] = useState<string>('');
  const [postsToUse, setPostsToUse] = useState(config.allCurrentPosts);
  const [queryParams, setQueryParams] = useState<Record<string, string | null> | null>(null);

  useEffect(() => {
    preFilters.type ? setActiveType(formatTypeFilterText[preFilters.type]) : setActiveType(config.allTypes[0]);
    preFilters.topic ? setActiveTopic(preFilters.topic) : setActiveTopic(config.allTopics[0]);
    const typeParams = getQueryStringValue('type');
    const topicParams = getQueryStringValue('topic');
    const validType = formatTypeFilterText[typeParams] || null;
    const validTopic = config.allTopics[queryStringTopics(config.allTopics).indexOf(topicParams)];
    setQueryParams({ type: validType, topic: validTopic });
  }, []);

  useEffect(() => {
    const isDefault = (filter: string) => filter.includes('All');
    const allTopics = isDefault(activeTopic);
    const allTypes = isDefault(activeType);

    const postsWithTopic = getPostsMatchingTopic(config.allCurrentPosts, activeTopic);
    const postsOfType = getPostsMatchingType(config.allCurrentPosts, activeType);

    const queriableTopic = queryStringTopics(config.allTopics)[config.allTopics.indexOf(activeTopic)];
    const queriableType = findActiveType(activeType);

    setQueryStringWithoutPageReload({ topic: queriableTopic, type: queriableType });

    if (allTypes && allTopics) {
      setPostsToUse(config.allCurrentPosts);
    }
    if (allTopics && !allTypes) {
      setPostsToUse(postsOfType);
    }
    if (!allTopics && allTypes) {
      setPostsToUse(postsWithTopic);
    }
    if (!allTopics && !allTypes) {
      setPostsToUse(getPostsMatchingType(postsWithTopic, activeType));
    }
  }, [activeType, activeTopic]);

  return (
    <PostsGrid background="brand5Solid">
      {!(preFilters.noTopics && preFilters.noTypes) && (
        <FiltersContainer>
          {!preFilters.noTopics && (
            <TopicFilter
              topics={config.allTopics}
              handleFilter={(topic: string) => setActiveTopic(topic)}
              queryType={queryParams?.topic}
            />
          )}
          {!preFilters.noTypes && (
            <TypeFilter
              types={config.allTypes}
              handleFilter={(type: string) => setActiveType(type)}
              queryType={queryParams?.type}
            />
          )}
        </FiltersContainer>
      )}
      {activeTopic !== 'All topics' && postsToUse.length !== 0 && (
        <TopicHeading textAlign="center">{activeTopic}</TopicHeading>
      )}
      {postsToUse.length !== 0 && <PaginatedItems items={postsToUse} itemsPerPage={6} />}
      {postsToUse.length === 0 && <DownState />}
    </PostsGrid>
  );
};

export default ContentHubCards;
